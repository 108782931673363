export class ItemComment {
  // REQ
  id: number
  itemId: number
  created: Date
  userId: number
  username: string

  // Should be set for everything to show up correctly,
  // but not fatal if unknown
  userAvatarUrl?: string
  userLevel: number = 0
  text?: string
  upvotes: number = 0
  downvotes: number = 0
  mediaUrl?: string

  constructor(id: number, itemId: number, created: Date, userId: number, username: string) {
    this.id = id
    this.itemId = itemId
    this.created = created
    this.userId = userId
    this.username = username
  }

  // Generates an Comment from an object.
  // Returns null if required information is missing.
  static fromDTO(obj: object): ItemComment | null {
    if (obj === undefined || obj == null) {
      return null
    }

    const asComm = obj as ItemComment
    if (asComm.id === undefined ||
      asComm.itemId === undefined ||
      asComm.created === undefined ||
      asComm.userId === undefined ||
      asComm.username === undefined) {
      console.error('Received comment dto without required information')
      console.log(obj)
      return null
    }

    const comment = new ItemComment(asComm.id, asComm.itemId, new Date(asComm.created),
      asComm.userId, asComm.username)

    comment.userAvatarUrl = asComm.userAvatarUrl
    comment.userLevel = asComm.userLevel
    comment.text = asComm.text
    comment.upvotes = asComm.upvotes
    comment.downvotes = asComm.downvotes
    comment.mediaUrl = asComm.mediaUrl

    return comment
  }
}
