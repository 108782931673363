import { Item } from '@/dto/Item'
import { ItemComment } from '@/dto/ItemComment'

export class ItemResponse {
  items: Item[]
  comments: ItemComment[]

  constructor(items: Item[], comments: ItemComment[]) {
    this.items = items
    this.comments = comments
  }

  // Generates an Item from an object.
  // Returns null if required information is missing.
  static fromDTO(obj: object): ItemResponse | null {
    if (obj === undefined || obj == null) {
      return null
    }

    const asResponse = obj as ItemResponse
    if (asResponse.items === undefined || asResponse.items.length === 0) {
      console.error('Received item dto without required information')
      console.log(obj)
      return null
    }

    const parsedItems: Item[] = []
    const parsedComments: ItemComment[] = []

    for (const item of asResponse.items) {
      const parsedItem = Item.fromDTO(item)

      // + Only process released items (WM-100)
      if (parsedItem && parsedItem.status === 1) {
        parsedItems.push(parsedItem)
      }
    }

    if (parsedItems.length === 0) {
      // No valid items (could not parse)
      return null
    }

    if (asResponse.comments !== undefined) {
      for (const comm of asResponse.comments) {
        const parsedComment = ItemComment.fromDTO(comm)
        if (parsedComment) {
          parsedComments.push(parsedComment)
        }
      }
    }

    return new ItemResponse(parsedItems, parsedComments)
  }
}
