
import { Tag } from './Tag'
import { ItemType } from './enums/ItemType'

export class Item {
  // Fully Required to show the item/content
  id: number
  type: ItemType
  url: string

  // Should be set for everything to show up correctly,
  // but not fatal if unknown
  title?: string
  username?: string
  userAvatarUrl?: string
  created?: Date
  status: number = 1
  views: number = 0
  upvotes: number = 0
  downvotes: number = 0
  shares: number = 0
  downloads: number = 0
  totalComments: number = 0
  tags: Tag[] = []
  ratio: number = 0.0
  thumbUrl?: string
  animatedThumbUrl?: string
  userLevel: number = 0

  constructor(itemId: number, type: ItemType, url: string) {
    this.id = itemId
    this.type = type
    this.url = url
  }

  // Generates an Item from an object.
  // Returns null if required information is missing.
  static fromDTO(obj: object): Item | null {
    if (obj === undefined || obj == null) {
      return null
    }

    const asItem = obj as Item
    if (asItem.id === undefined || asItem.type === undefined || asItem.url === undefined) {
      console.error('Received item dto without required information')
      console.log(obj)
      return null
    }

    const item = new Item(asItem.id, asItem.type, asItem.url)
    const created = asItem.created
    if (created) {
      item.created = new Date(created)
    }

    item.title = asItem.title
    item.username = asItem.username
    item.userAvatarUrl = asItem.userAvatarUrl
    item.views = asItem.views
    item.upvotes = asItem.upvotes
    item.downvotes = asItem.downvotes
    item.shares = asItem.shares
    item.downloads = asItem.downloads
    item.totalComments = asItem.totalComments
    item.tags = asItem.tags
    item.status = asItem.status
    item.ratio = asItem.ratio
    item.thumbUrl = asItem.thumbUrl
    item.animatedThumbUrl = asItem.animatedThumbUrl
    item.userLevel = asItem.userLevel

    return item
  }
}
